import { jwtDecode } from 'jwt-decode';

export const register = async (email, password, role, username, team = null) => {
  const response = await fetch('https://backend.moorpan.com/register', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ email, password, role, username, team }),
  });

  const data = await response.json();
  if (!response.ok) {
    throw new Error(data.message || 'Registration failed');
  }

  return data;
};

export const login = async (email, password) => {
  const response = await fetch('https://backend.moorpan.com/login', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ email, password }),
  });

  const data = await response.json();

  if (!response.ok) {
    throw new Error(data.message || 'Login failed');
  }

  localStorage.setItem('authToken', data.token);
  localStorage.setItem('email', email);

  const decodedToken = jwtDecode(data.token);

  const { role, username, team } = decodedToken;

  localStorage.setItem('role', role);
  localStorage.setItem('username', username);

  if (team) {
    localStorage.setItem('team', JSON.stringify(team));
  }

  return { token: data.token, role, username, team };
};

export const logout = () => {
  localStorage.removeItem('authToken');
  localStorage.removeItem('email');
  localStorage.removeItem('role');
  localStorage.removeItem('username');
  localStorage.removeItem('team');
};

export const getToken = () => {
  return localStorage.getItem('authToken');
};
