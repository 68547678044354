import React, { useState, useMemo, useEffect } from "react";
import {
  Box,
  IconButton,
  Typography,
  useMediaQuery,
  Divider,
  Checkbox,
  FormControlLabel,
  Button,
  FormGroup,
} from "@mui/material";
import { DataGrid, getGridStringOperators } from "@mui/x-data-grid";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Bar, Doughnut } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from "chart.js";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import axios from "axios";
import dayjs from "dayjs";
import Loader from "../components/Loader";
import { processAffiliateData } from "../utils";
import { dataGridStyles } from "../styles";
import RefreshIcon from "@mui/icons-material/Refresh";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement
);

const AffiliateAnalytics = () => {
  const [leads, setLeads] = useState([]);
  const [conversions, setConversions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [fromDate, setFromDate] = useState(dayjs().startOf("day"));
  const [toDate, setToDate] = useState(dayjs().endOf("day"));
  const [expandedRows, setExpandedRows] = useState({});
  const [selectedNames, setSelectedNames] = useState([]);
  const [allNames, setAllNames] = useState([]);
  const isMobile = useMediaQuery("(max-width:600px)");

  const stringOnlyContainsOperators = getGridStringOperators().filter(
    (operator) => operator.value === "contains"
  );

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        "https://backend.moorpan.com/get-leads-and-conversions",
        {
          params: {
            fromDate: fromDate.format("YYYY-MM-DD HH:mm:ss"),
            toDate: toDate.format("YYYY-MM-DD HH:mm:ss"),
            itemsPerPage: 1000,
          },
        }
      );

      setLeads(response.data.leads);
      setConversions(response.data.conversions);

      const uniqueNames = [
        ...new Set(
          response.data.leads.map((item) => item.brokerName || "unknown")
        ),
      ];
      setAllNames(uniqueNames);
      setSelectedNames(uniqueNames);
    } catch (error) {
      console.error("Ошибка при загрузке данных:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fromDate, toDate]);

  const rows = useMemo(() => {
    const processedData = processAffiliateData(
      leads,
      conversions,
      selectedNames
    );
    return processedData;
  }, [leads, conversions, selectedNames]);

  const totalLeads = rows.reduce((acc, row) => acc + row.leads, 0);
  const totalDeposits = rows.reduce((acc, row) => acc + row.deposit, 0);
  const totalCr = ((totalDeposits / totalLeads) * 100).toFixed(2);

  const totalRow = {
    id: "total",
    name: "Total",
    leads: totalLeads,
    deposit: totalDeposits,
    cr: totalCr,
  };

  const columns = [
    {
      field: "toggle",
      headerName: "",
      renderCell: (params) => {
        if (params.row.id === "total") return null;
        const isExpanded = expandedRows[params.row.id];
        return (
          <IconButton onClick={() => handleRowClick(params)} size="small">
            {isExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        );
      },
      width: 50,
      sortable: false,
    },
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      sortComparator: (v1, v2, params1, params2) => {
        if (params1.id === "total" || params2.id === "total") return 0;
        return v1.localeCompare(v2);
      },
      filterOperators: stringOnlyContainsOperators,
    },
    {
      field: "leads",
      headerName: "Leads",
      flex: 1,
      sortComparator: (v1, v2, params1, params2) => {
        if (params1.id === "total" || params2.id === "total") return 0;
        return v1 - v2;
      },
      filterOperators: stringOnlyContainsOperators,
    },
    {
      field: "deposit",
      headerName: "Deposits",
      flex: 1,
      sortComparator: (v1, v2, params1, params2) => {
        if (params1.id === "total" || params2.id === "total") return 0;
        return v1 - v2;
      },
      filterOperators: stringOnlyContainsOperators,
    },
    {
      field: "cr",
      headerName: "CR (%)",
      flex: 1,
      renderCell: (params) => `${params.value}%`,
      sortComparator: (v1, v2, params1, params2) => {
        if (params1.id === "total" || params2.id === "total") return 0;
        return parseFloat(v1) - parseFloat(v2);
      },
      filterOperators: stringOnlyContainsOperators,
    },
  ];

  const handleRowClick = (params) => {
    const id = params.row.id;
    setExpandedRows((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  const renderAffiliateChart = () => {
    const chartData = {
      labels: rows.map((row) => row.name),
      datasets: [
        {
          label: "Leads",
          data: rows.map((row) => row.leads),
          backgroundColor: "#36A2EB",
        },
        {
          label: "Deposits",
          data: rows.map((row) => row.deposit),
          backgroundColor: "#FF6384",
        },
        {
          label: "CR (%)",
          data: rows.map((row) => row.cr),
          backgroundColor: "#FFCE56",
        },
      ],
    };

    const options = {
      responsive: true,
      plugins: {
        legend: {
          display: true,
        },
      },
    };

    return <Bar data={chartData} options={options} />;
  };

  const renderGeoChart = (geoData) => {
    const chartData = {
      labels: Object.keys(geoData),
      datasets: [
        {
          data: Object.values(geoData).map((geo) => geo.leads),
          backgroundColor: [
            "#FF6384",
            "#36A2EB",
            "#FFCE56",
            "#4BC0C0",
            "#9966FF",
          ],
        },
      ],
    };

    return <Doughnut data={chartData} />;
  };

  const handleSelectAll = () => setSelectedNames(allNames);
  const handleUnselectAll = () => setSelectedNames([]);

  const setToday = () => {
    setFromDate(dayjs().startOf("day"));
    setToDate(dayjs().endOf("day"));
  };

  const setYesterday = () => {
    setFromDate(dayjs().subtract(1, "day").startOf("day"));
    setToDate(dayjs().subtract(1, "day").endOf("day"));
  };

  const setLastWeek = () => {
    setFromDate(dayjs().subtract(1, "week").startOf("week"));
    setToDate(dayjs().subtract(1, "week").endOf("week"));
  };

  const setLastMonth = () => {
    setFromDate(dayjs().subtract(1, "month").startOf("month"));
    setToDate(dayjs().subtract(1, "month").endOf("month"));
  };

  const setThisWeek = () => {
    setFromDate(dayjs().startOf("week"));
    setToDate(dayjs().endOf("week"));
  };

  const setThisMonth = () => {
    setFromDate(dayjs().startOf("month"));
    setToDate(dayjs().endOf("month"));
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box>
        <Typography
          variant="h4"
          align="center"
          gutterBottom
          sx={{ marginBottom: "20px", marginTop: "20px" }}
        >
          Affiliate Data
        </Typography>

        <Typography variant="h5" gutterBottom sx={{ margin: "0 0 12px 0" }}>
          Filter by date
        </Typography>

        <Box sx={{ display: "flex", justifyContent: "flex-start", mb: 2 }}>
          <DatePicker
            label="From Date"
            value={fromDate}
            onChange={(date) => setFromDate(date)}
            format="DD/MM/YYYY"
          />
          <Divider
            orientation="vertical"
            variant="middle"
            flexItem
            sx={{ margin: "0 12px" }}
          />
          <DatePicker
            label="To Date"
            value={toDate}
            onChange={(date) => setToDate(date)}
            format="DD/MM/YYYY"
          />
          <IconButton
            onClick={fetchData}
            color="primary"
            sx={{ marginLeft: "12px" }}
          >
            <RefreshIcon />
          </IconButton>
        </Box>

        <Box sx={{ display: "flex", gap: 6, mb: 2, mt: 2 }}>
          <Button onClick={setToday} variant="outlined" size="medium">
            Today
          </Button>
          <Button onClick={setYesterday} variant="outlined" size="medium">
            Yesterday
          </Button>
          <Button onClick={setLastWeek} variant="outlined" size="medium">
            Last Week
          </Button>
          <Button onClick={setLastMonth} variant="outlined" size="medium">
            Last Month
          </Button>
          <Button onClick={setThisWeek} variant="outlined" size="small">
            This Week
          </Button>
          <Button onClick={setThisMonth} variant="outlined" size="small">
            This Month
          </Button>
        </Box>

        <Typography variant="h5" gutterBottom>
          Filter by Broker
        </Typography>

        <FormGroup row>
          <Button
            onClick={handleSelectAll}
            variant="outlined"
            color="primary"
            sx={{ marginRight: "16px" }}
          >
            Select All
          </Button>
          <Button
            onClick={handleUnselectAll}
            variant="outlined"
            color="primary"
            sx={{ marginRight: "16px" }}
          >
            Unselect All
          </Button>
          {allNames.map((name) => (
            <FormControlLabel
              key={name}
              control={
                <Checkbox
                  checked={selectedNames.includes(name)}
                  onChange={() =>
                    setSelectedNames((prev) =>
                      prev.includes(name)
                        ? prev.filter((n) => n !== name)
                        : [...prev, name]
                    )
                  }
                  color="primary"
                />
              }
              label={name}
            />
          ))}
        </FormGroup>

        {loading ? (
          <Loader />
        ) : (
          <DataGrid
            rows={[
              ...rows.map((row, index) => ({ id: index, ...row })),
              totalRow,
            ]}
            columns={columns}
            pageSize={5}
            rowsPerPageOptions={[5, 10, 25]}
            sx={dataGridStyles}
            getRowHeight={() => 52}
          />
        )}

        {rows.map(
          (row) =>
            expandedRows[row.id] && (
              <Box
                key={row.id}
                sx={{ marginTop: "10px", backgroundColor: "#fff" }}
              >
                <Typography variant="h5" align="center" gutterBottom>
                  Geo Data for {row.name}
                </Typography>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: isMobile ? "column" : "row",
                    justifyContent: "space-between",
                  }}
                >
                  <Box sx={{ width: isMobile ? "100%" : "60%" }}>
                    <DataGrid
                      rows={Object.values(row.geoData).map((geo, geoIndex) => ({
                        id: geoIndex,
                        countryCode: geo.countryCode,
                        leads: geo.leads,
                        deposit: geo.deposit,
                        cr: geo.cr,
                      }))}
                      columns={[
                        {
                          field: "countryCode",
                          headerName: "Country",
                          flex: 1,
                          sortable: false,
                          filterOperators: stringOnlyContainsOperators,
                        },
                        {
                          field: "leads",
                          headerName: "Leads",
                          flex: 1,
                          sortable: true,
                          filterOperators: stringOnlyContainsOperators,
                        },
                        {
                          field: "deposit",
                          headerName: "Deposits",
                          flex: 1,
                          sortable: true,
                          filterOperators: stringOnlyContainsOperators,
                        },
                        {
                          field: "cr",
                          headerName: "CR (%)",
                          flex: 1,
                          renderCell: (params) => `${params.value}%`,
                          sortable: true,
                          filterOperators: stringOnlyContainsOperators,
                        },
                      ]}
                      pageSize={3}
                      rowsPerPageOptions={[3]}
                      getRowClassName={(params) =>
                        params.indexRelativeToCurrentPage % 2 === 0
                          ? "MuiDataGrid-even-row"
                          : ""
                      }
                      sx={dataGridStyles}
                    />
                  </Box>

                  <Box
                    sx={{
                      width: isMobile ? "100%" : "35%",
                      marginTop: isMobile ? "20px" : "0",
                    }}
                  >
                    {renderGeoChart(row.geoData)}
                  </Box>
                </Box>
              </Box>
            )
        )}

        <Box sx={{ paddingBottom: "20px" }}>
          <Typography
            variant="h4"
            align="center"
            gutterBottom
            sx={{ marginBottom: "20px", marginTop: "20px" }}
          >
            Affiliate Data Chart
          </Typography>
          {renderAffiliateChart()}
        </Box>
      </Box>
    </LocalizationProvider>
  );
};

export default AffiliateAnalytics;
