import React, { useState, useEffect, useMemo } from 'react';
import { DataGrid, getGridStringOperators } from '@mui/x-data-grid';
import { Box, Typography } from '@mui/material';
import { dataGridStyles } from '../styles';
import Loader from '../components/Loader';
import axios from 'axios';
import dayjs from 'dayjs';

const DomainsAnalitics = () => {
  const [projectsData, setProjectsData] = useState({});
  const [allDomains, setAllDomains] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const stringOnlyContainsOperators = getGridStringOperators().filter((operator) => operator.value === 'contains');
  const role = localStorage.getItem('role');
  const username = localStorage.getItem('username');
  const team = role === 'tlead' ? JSON.parse(localStorage.getItem('team')) : null;

  const memoizedTeam = useMemo(() => JSON.stringify(team), [team]);

  useEffect(() => {
    const fetchDomainsData = async () => {
      try {
        const params = { role, username };
        if (team) {
          params.team = memoizedTeam;
        }
  
        const response = await axios.get('https://backend.moorpan.com/get-domains', { params });
        setProjectsData(response.data);
  
        const allDomainsList = Object.keys(response.data).reduce((acc, project) => {
          const domainsWithProject = response.data[project]
            .filter((domain) => !domain.status?.startsWith('Trash Hold'))
            .map((domain) => ({
              ...domain,
              project,
            }));
          return [...acc, ...domainsWithProject];
        }, []);
        setAllDomains(allDomainsList);
      } catch (err) {
        console.log(err);
        setError('Ошибка при загрузке данных');
      } finally {
        setLoading(false);
      }
    };
  
    fetchDomainsData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [role, username, memoizedTeam]);
  

  const calculateLifeDuration = (dateChecked) => {
    if (!dateChecked) return null;
    const now = dayjs();
    const checkedDate = dayjs(dateChecked);
    return now.diff(checkedDate, 'day');
  };

  const getRowClassName = (lifeDuration) => {
    if (lifeDuration === null) return '';
    if (lifeDuration <= 7) return 'row-red';
    if (lifeDuration <= 14) return 'row-yellow';
    if (lifeDuration <= 31) return 'row-orange';
    return 'row-green';
  };

  const renderProjectTables = useMemo(() => {
    return Object.keys(projectsData).map((project) => (
      <Box key={project} sx={{ marginBottom: 4 }}>
        <Typography variant="h5" align="center" gutterBottom>
          {project} Domains Analytics
        </Typography>
        <DataGrid
          rows={projectsData[project]}
          columns={[
            { field: 'domain', headerName: 'Domain', flex: 1,
              sortable: false,
              filterOperators: stringOnlyContainsOperators, },
            { field: 'status', headerName: 'Status', flex: 1,
              sortable: false,
              filterOperators: stringOnlyContainsOperators, },
            { field: 'status_ads', headerName: 'Status Ads', flex: 1,
              sortable: false,
              filterOperators: stringOnlyContainsOperators, },
            {
              field: 'date_checked',
              headerName: 'Date start index',
              flex: 1,
              type: 'dateTime',
              valueGetter: (params) => {
                const dateChecked = params;
                return dateChecked ? new Date(dateChecked) : '';
              },
              sortable: true,
              filterOperators: stringOnlyContainsOperators,
            },
            {
              field: 'lifeDuration',
              headerName: 'Life Duration (days)',
              flex: 1,
              renderCell: (cellValues) => {
                if (!cellValues || !cellValues.row) {
                  return "No Data";
                }
                const dateChecked = cellValues.row.date_checked;
                return dateChecked ? calculateLifeDuration(dateChecked) : "No Data";
              },
              sortable: true,
              filterOperators: stringOnlyContainsOperators,
            },
          ]}
          initialState={{
            pagination: {
              paginationModel: { pageSize: 25, page: 0 },
            },
          }}
          pageSizeOptions={[25, 50, 100, 250, { value: -1, label: 'All' }]}
          getRowId={(row) => row.domain}
          getRowClassName={(params) => {
            if (!params || !params.row) {
              return '';
            }
            const dateChecked = params.row?.date_checked;
            if (!dateChecked) {
              return '';
            }
           return getRowClassName(calculateLifeDuration(dateChecked))}}
          sx={dataGridStyles}
        />
      </Box>
    ));
  }, [projectsData, stringOnlyContainsOperators]);

  if (loading) {
    return <Loader />;
  }

  if (error) {
    return <Typography variant="h6" color="error" align="center">{error}</Typography>;
  }

  return (
    <Box>
      <Typography variant="h4" align="center" gutterBottom>
        Domains Analytics
      </Typography>
  
      {(role === 'admin' || role === 'tlead') && (
        <Box sx={{ marginBottom: 4 }}>
          <Typography variant="h5" align="center" gutterBottom>
            All Domains Analytics
          </Typography>
          <DataGrid
            rows={allDomains}
            columns={[
              { field: 'domain', headerName: 'Domain', flex: 1, sortable: false, filterOperators: stringOnlyContainsOperators },
              { field: 'project', headerName: 'Buyer', flex: 1, sortable: false, filterOperators: stringOnlyContainsOperators },
              { field: 'status', headerName: 'Status', flex: 1, sortable: false, filterOperators: stringOnlyContainsOperators },
              { field: 'status_ads', headerName: 'Status Ads', flex: 1, sortable: false, filterOperators: stringOnlyContainsOperators },
              {
                field: 'date_checked',
                headerName: 'Date start index',
                flex: 1,
                type: 'dateTime',
                valueGetter: (params) => {
                  const dateChecked = params;
                  return dateChecked ? new Date(dateChecked) : '';
                },
                sortable: true,
                filterOperators: stringOnlyContainsOperators,
              },
              {
                field: 'lifeDuration',
                headerName: 'Life Duration (days)',
                flex: 1,
                renderCell: (cellValues) => {
                  if (!cellValues || !cellValues.row) {
                    return "No Data";
                  }
                  const dateChecked = cellValues.row.date_checked;
                  return dateChecked ? calculateLifeDuration(dateChecked) : "No Data";
                },
                sortable: true,
                filterOperators: stringOnlyContainsOperators,
              },
            ]}
            initialState={{
              pagination: {
                paginationModel: { pageSize: 25, page: 0 },
              },
            }}
            pageSizeOptions={[25, 50, 100, 250, { value: -1, label: 'All' }]}
            getRowId={(row) => row.domain}
            getRowClassName={(params) => {
              if (!params || !params.row) {
                return '';
              }
              const dateChecked = params.row?.date_checked;
              if (!dateChecked) {
                return '';
              }
              return getRowClassName(calculateLifeDuration(dateChecked));
            }}
            sx={dataGridStyles}
          />
        </Box>
      )}
  
      {role === 'user' && renderProjectTables}
    </Box>
  );
  
};

export default DomainsAnalitics;
