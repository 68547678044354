import React, { useState, useEffect, useMemo } from "react";
import Box from "@mui/material/Box";
import { createTheme } from "@mui/material/styles";
import BarChartIcon from "@mui/icons-material/BarChart";
import PeopleIcon from "@mui/icons-material/People";
import { AppProvider } from "@toolpad/core/AppProvider";
import { DashboardLayout } from "@toolpad/core/DashboardLayout";
import lightLogo from "./images/dark-logo.svg";
import darkLogo from "./images/logo.svg";
import MediaBuyerAnalytics from "./pages/MediaBuyerAnalytics";
import AdvertiserAnalytics from "./pages/AdvertiserAnalytics";
import AccountsAnalitics from "./pages/AccountsAnalitics";
import DomainsAnalitics from "./pages/DomainsAnalitics";
import GlobalStyles from "@mui/material/GlobalStyles";
import { useNavigate } from "react-router-dom";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import DomainIcon from "@mui/icons-material/Domain";
import Leaderboard from "./pages/Leaderboard";
import TrashHoldDomains from "./pages/TrashHoldDomains";
import InsightsIcon from "@mui/icons-material/Insights";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import AdminSetting from "./pages/AdminSetting";
import SettingsApplicationsIcon from "@mui/icons-material/SettingsApplications";

const inputGlobalStyles = (
  <GlobalStyles
    styles={{
      "& main.MuiBox-root": {
        minWidth: "80%",
        boxSizing: "border-box",
      },
    }}
  />
);

const getTheme = createTheme({
  cssVariables: {
    colorSchemeSelector: "data-toolpad-color-scheme",
  },
  colorSchemes: { light: true, dark: true },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 600,
      lg: 1200,
      xl: 1536,
    },
  },
  typography: {
    fontFamily: "Poppins, Arial, sans-serif",
  },
});

export default function DashboardLayoutBranding() {
  const [pathname, setPathname] = useState("/");
  const [currentLogo, setCurrentLogo] = useState(lightLogo);
  const [email, setEmail] = useState(
    localStorage.getItem("email") || "admin@example.com"
  );
  const [role, setRole] = useState(localStorage.getItem("role") || "");
  const navigate = useNavigate();

  const AUTHENTICATION = {
    signIn: () => {},
    signOut: () => {
      localStorage.removeItem("authToken");
      localStorage.removeItem("username");
      localStorage.removeItem("email");
      localStorage.removeItem("role");
      localStorage.removeItem("team");
      navigate("/login");
    },
  };

  useEffect(() => {
    const handleRoleChange = () => {
      setRole(localStorage.getItem("role") || "");
    };

    const handleStorageChange = () => {
      setEmail(localStorage.getItem("email") || "admin@example.com");
      handleRoleChange();
    };

    window.addEventListener("storage", handleStorageChange);
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  const NAVIGATION = useMemo(
    () => [
      ...(role === "admin" ||
      role === "user" ||
      role === "tlead"
        ? [
            {
              segment: "leaderboard",
              title: "Lead's board",
              icon: <BarChartIcon />,
            },
          ]
        : []),
      ...(role === "admin" ||
      role === "user" ||
      role === "finance" ||
      role === "tlead"
        ? [
            {
              segment: "media-buyer-analytics",
              title: "Media Buyer Analytics",
              icon: <PeopleIcon />,
            },
          ]
        : []),
      ...(role === "admin"
        ? [
            {
              segment: "advertiser-analytics",
              title: "Advertiser Analytics",
              icon: <BarChartIcon />,
            },
          ]
        : []),
      ...(role === "admin" ||
      role === "user" ||
      role === "finance" ||
      role === "accm" ||
      role === "tlead"
        ? [
            {
              segment: "accounts-analytics",
              title: "Accounts Analytics",
              icon: <AccountBalanceIcon />,
            },
          ]
        : []),
      ...(role === "admin" ||
      role === "user" ||
      role === "tlead"
        ? [
            {
              segment: "domains",
              title: "Domains",
              icon: <DomainIcon />,
              kind: "page",
              children: [
                {
                  segment: "domains-analytics",
                  title: "Domains Analytics",
                  icon: <InsightsIcon />,
                  kind: "page",
                },
                {
                  segment: "trash-hold-domains",
                  title: "Trash Hold Domains",
                  icon: <CurrencyExchangeIcon />,
                  kind: "page",
                },
              ],
            },
          ]
        : []),
      ...(role === "admin"
        ? [
            {
              segment: "admin-setting",
              title: "Admin Setting",
              icon: <SettingsApplicationsIcon />,
            },
          ]
        : []),
    ],
    [role]
  );

  const router = useMemo(() => {
    return {
      pathname,
      searchParams: new URLSearchParams(),
      navigate: (path) => setPathname(String(path)),
    };
  }, [pathname]);

  const handleLogoSwitch = () => {
    setCurrentLogo((prevLogo) =>
      prevLogo === lightLogo ? darkLogo : lightLogo
    );
  };

  useEffect(() => {
    const themeSwitchButton = document.querySelector(
      'button[aria-label="Switch to dark mode"], button[aria-label="Switch to light mode"]'
    );

    if (themeSwitchButton) {
      themeSwitchButton.addEventListener("click", handleLogoSwitch);
    }

    return () => {
      if (themeSwitchButton) {
        themeSwitchButton.removeEventListener("click", handleLogoSwitch);
      }
    };
  }, []);

  const renderContent = () => {
    switch (pathname) {
      case "/media-buyer-analytics":
        return <MediaBuyerAnalytics />;
      case "/advertiser-analytics":
        return <AdvertiserAnalytics />;
      case "/accounts-analytics":
        return <AccountsAnalitics />;
      case "/domains/domains-analytics":
        return <DomainsAnalitics />;
      case "/domains/trash-hold-domains":
        return <TrashHoldDomains />;
      case "/leaderboard":
        return <Leaderboard />;
      case "/admin-setting":
        return <AdminSetting />;
      default:
        if (role === 'finance') {
          console.log('work1');
          return <MediaBuyerAnalytics />
        } else if (role === 'accm') {
          console.log('work');
          return <AccountsAnalitics />
        } else {
          console.log('work3');
          return <Leaderboard />;
        }
    }
  };

  return (
    <AppProvider
      navigation={NAVIGATION}
      theme={getTheme}
      branding={{
        logo: <img src={currentLogo} alt="CRM2FTDS Logo" />,
        title: "",
      }}
      router={router}
      authentication={AUTHENTICATION}
      session={{
        user: { name: localStorage.getItem("username") || "Anonymous", email },
      }}
    >
      {inputGlobalStyles}
      <DashboardLayout>
        <Box sx={{ p: 2 }}>{renderContent()}</Box>
      </DashboardLayout>
    </AppProvider>
  );
}
