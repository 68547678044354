import React, { useState, useEffect } from 'react';
import { DataGrid, getGridStringOperators } from '@mui/x-data-grid';
import { Box, Typography } from '@mui/material';
import { dataGridStyles } from '../styles';
import Loader from '../components/Loader';
import axios from 'axios';
import dayjs from 'dayjs';

const TrashHoldDomains = () => {
  const [allDomains, setAllDomains] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const stringOnlyContainsOperators = getGridStringOperators().filter((operator) => operator.value === 'contains');

  useEffect(() => {
    const fetchDomainsData = async () => {
      setLoading(true);
      try {
        const role = localStorage.getItem('role');
        const username = localStorage.getItem('username');
        const team = role === 'tlead' ? JSON.parse(localStorage.getItem('team')) : null;
  
        const params = {
          role,
          username,
        };
  
        if (team) {
          params.team = JSON.stringify(team);
        }
  
        const response = await axios.get('https://backend.moorpan.com/get-trash-hold-domains', {
          params,
        });
  
        const updatedData = Object.entries(response.data).reduce((acc, [project, domains]) => {
          const filteredDomains = domains.map((domain) => ({ ...domain, project }));
  
          if (filteredDomains.length > 0) {
            acc[project] = filteredDomains;
          }
  
          return acc;
        }, {});
  
        setAllDomains(updatedData);
      } catch (err) {
        setError('Ошибка при загрузке данных');
      } finally {
        setLoading(false);
      }
    };
  
    fetchDomainsData();
  }, []);

  const calculateLifeDuration = (dateChecked) => {
    if (!dateChecked) return null;
    const now = dayjs();
    const checkedDate = dayjs(dateChecked);
    return now.diff(checkedDate, 'day');
  };

  const getRowClassName = (lifeDuration) => {
    if (lifeDuration === null) return '';
    if (lifeDuration <= 7) return 'row-red';
    if (lifeDuration <= 14) return 'row-yellow';
    if (lifeDuration <= 31) return 'row-orange';
    return 'row-green';
  };

  if (loading) {
    return <Loader />;
  }

  if (error) {
    return <Typography variant="h6" color="error" align="center">{error}</Typography>;
  }

  return (
    <Box>
      <Typography variant="h4" align="center" gutterBottom>
        Trash Hold Domains
      </Typography>

      <Box sx={{ marginBottom: 4 }}>
        <DataGrid
          rows={Object.values(allDomains).flat()}
          columns={[
            { field: 'domain', headerName: 'Domain', flex: 1, sortable: false, filterOperators: stringOnlyContainsOperators },
            { field: 'project', headerName: 'Buyer', flex: 1, sortable: false, filterOperators: stringOnlyContainsOperators },
            { field: 'status', headerName: 'Status', flex: 1, sortable: false, filterOperators: stringOnlyContainsOperators },
            { field: 'status_ads', headerName: 'Status Ads', flex: 1, sortable: false, filterOperators: stringOnlyContainsOperators },
            {
              field: 'date_checked',
              headerName: 'Date start index',
              flex: 1,
              type: 'dateTime',
              valueGetter: (params) => {
                const dateChecked = params;
                return dateChecked ? new Date(dateChecked) : '';
              },
              sortable: false,
              filterOperators: stringOnlyContainsOperators,
            },
            {
              field: 'date_dead',
              headerName: 'Date end index',
              flex: 1,
              type: 'dateTime',
              valueGetter: (params) => {
                const dateDead = params;
                return dateDead ? new Date(dateDead) : '';
              },
              sortable: false,
              filterOperators: stringOnlyContainsOperators,
            },
            {
              field: 'lifeDuration',
              headerName: 'Life Duration (days)',
              flex: 1,
              renderCell: (cellValues) => {
                if (!cellValues || !cellValues.row) {
                  return "No Data";
                }
                const dateChecked = cellValues.row.date_checked;
                return dateChecked ? calculateLifeDuration(dateChecked) : "No Data";
              },
              sortable: false,
              filterOperators: stringOnlyContainsOperators,
            },
          ]}
          initialState={{
            pagination: {
              paginationModel: { pageSize: 25, page: 0 },
            },
          }}
          pageSizeOptions={[25, 50, 100, 250, { value: -1, label: 'All' }]}
          getRowId={(row) => row.domain}
          getRowClassName={(params) => {
            if (!params || !params.row) {
              return '';
            }
            const dateChecked = params.row?.date_checked;
            if (!dateChecked) {
              return '';
            }
            return getRowClassName(calculateLifeDuration(dateChecked));
          }}
          sx={dataGridStyles}
        />
      </Box>
    </Box>
  );
};

export default TrashHoldDomains;
