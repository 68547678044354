import React, { useState, useMemo, useEffect } from "react";
import { DataGrid, getGridStringOperators } from "@mui/x-data-grid";
import {
  Box,
  Typography,
  Select,
  MenuItem,
  TextField,
  Divider,
  Button,
  useMediaQuery,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import axios from "axios";
import Loader from "../components/Loader";
import { dataGridStyles } from "../styles";
import { IconButton } from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

const Leaderboard = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [fromDate, setFromDate] = useState(dayjs().startOf("day"));
  const [toDate, setToDate] = useState(dayjs().endOf("day"));
  const [selectedBuyer, setSelectedBuyer] = useState("");
  const [selectedSaleStatus, setSelectedSaleStatus] = useState("");
  const [selectedHasFTD, setSelectedHasFTD] = useState("");
  const [emailFilter, setEmailFilter] = useState("");
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({});
  const [showTestTable, setShowTestTable] = useState(false);
  const [testData, setTestData] = useState([]);
  const isMobile = useMediaQuery("(max-width:600px)");

  const role = localStorage.getItem("role");
  const username = localStorage.getItem("username");
  const tableName = "Leaderboard";

  const stringOnlyContainsOperators = getGridStringOperators().filter(
    (operator) => operator.value === "contains"
  );

  const buyers = [...new Set(data.map((item) => item.custom4).filter(Boolean))];
  const saleStatuses = [
    ...new Set(data.map((item) => item.saleStatus).filter(Boolean)),
  ];

  const fetchTestData = async () => {
    setLoading(true);
    try {
      const params = {
        fromDate: fromDate.format("YYYY-MM-DD HH:mm:ss"),
        toDate: toDate.format("YYYY-MM-DD HH:mm:ss"),
        itemsPerPage: 1000,
      };

      if (role === "user" && username) {
        params.custom4 = username;
      }

      const response = await axios.get(
        "https://backend.moorpan.com/get-test-leads",
        { params }
      );
      let items = response.data.items;

      if (role === "tlead") {
        const team = JSON.parse(localStorage.getItem("team"));
        items = items.filter((item) => team.includes(item.custom4));
      }

      setTestData(items);
    } catch (error) {
      console.error("Ошибка при загрузке тестовых лидов:", error);
    } finally {
      setLoading(false);
    }
  };

  const setToday = () => {
    setFromDate(dayjs().startOf("day"));
    setToDate(dayjs().endOf("day"));
  };

  const setYesterday = () => {
    setFromDate(dayjs().subtract(1, "day").startOf("day"));
    setToDate(dayjs().subtract(1, "day").endOf("day"));
  };

  const setLastWeek = () => {
    setFromDate(dayjs().subtract(1, "week").startOf("week"));
    setToDate(dayjs().subtract(1, "week").endOf("week"));
  };

  const setLastMonth = () => {
    setFromDate(dayjs().subtract(1, "month").startOf("month"));
    setToDate(dayjs().subtract(1, "month").endOf("month"));
  };

  const setThisWeek = () => {
    setFromDate(dayjs().startOf("week"));
    setToDate(dayjs().endOf("week"));
  };

  const setThisMonth = () => {
    setFromDate(dayjs().startOf("month"));
    setToDate(dayjs().endOf("month"));
  };

  const handleToggleTestTable = () => {
    if (!showTestTable) {
      fetchTestData();
    }
    setShowTestTable(!showTestTable);
  };

  const fetchData = async () => {
    setLoading(true);
    try {
      const params = {
        fromDate: fromDate.format("YYYY-MM-DD HH:mm:ss"),
        toDate: toDate.format("YYYY-MM-DD HH:mm:ss"),
      };

      if (role === "user" && username) {
        params.custom4 = username;
      }

      const response = await axios.get(
        "https://backend.moorpan.com/get-leads",
        { params }
      );

      let items = response.data.items;

      if (role === "tlead") {
        const team = JSON.parse(localStorage.getItem("team"));
        items = items.filter((item) => team.includes(item.custom4));
      }

      setData(items);
    } catch (error) {
      console.error("Ошибка при загрузке данных:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fromDate, toDate]);

  const fetchAllData = async () => {
    setLoading(true);
    try {
      await fetchData();
      await fetchTestData();
    } catch (error) {
      console.error("Ошибка при загрузке данных:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchColumnSettings = async () => {
    try {
      const response = await axios.get(
        "https://backend.moorpan.com/get-column-settings",
        {
          params: { username, tableName },
        }
      );

      if (response.data) {
        const visibilityModel = response.data.reduce((acc, col) => {
          acc[col.field] = !col.hide;
          return acc;
        }, {});

        setColumnVisibilityModel(visibilityModel);
      }
    } catch (error) {
      console.error("Ошибка при получении настроек колонок:", error);
    }
  };

  useEffect(() => {
    fetchColumnSettings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [username, tableName]);

  const handleColumnVisibilityChange = async (newVisibilityModel) => {
    setColumnVisibilityModel(newVisibilityModel);

    const changedColumns = Object.keys(newVisibilityModel).map((field) => ({
      field,
      hide: !newVisibilityModel[field],
    }));

    try {
      await axios.post("https://backend.moorpan.com/save-column-settings", {
        username,
        tableName,
        columnConfig: changedColumns,
      });
      console.log("Настройки видимости колонок сохранены");
    } catch (error) {
      console.error("Ошибка при сохранении настроек видимости колонок:", error);
    }
  };

  const rows = useMemo(() => {
    return data
      .map((row) => ({
        ...row,
        formattedSignupDate: dayjs(row.signupDate).format(
          "DD/MM/YYYY HH:mm:ss"
        ),
      }))
      .filter(
        (row) =>
          (selectedBuyer ? row.custom4 === selectedBuyer : true) &&
          (selectedSaleStatus ? row.saleStatus === selectedSaleStatus : true) &&
          (selectedHasFTD
            ? selectedHasFTD === "Yes"
              ? row.hasFTD === 1
              : row.hasFTD === 0
            : true) &&
          (emailFilter
            ? row.email.toLowerCase().includes(emailFilter.toLowerCase())
            : true)
      );
  }, [data, selectedBuyer, selectedSaleStatus, selectedHasFTD, emailFilter]);

  const columns = [
    {
      field: "countryName",
      headerName: "Country",
      maxWidth: 150,
      align: "center",
      headerAlign: "center",
      sortable: false,
      filterOperators: stringOnlyContainsOperators,
    },
    {
      field: "cityName",
      headerName: "City",
      maxWidth: 200,
      align: "center",
      headerAlign: "center",
      sortable: false,
      filterOperators: stringOnlyContainsOperators,
    },
    {
      field: "custom",
      headerName: "Custom",
      mixWidth: 40,
      align: "center",
      headerAlign: "center",
      sortable: false,
      filterOperators: stringOnlyContainsOperators,
    },
    {
      field: "custom1",
      headerName: "Custom1",
      minWidth: 40,
      align: "center",
      headerAlign: "center",
      sortable: false,
      filterOperators: stringOnlyContainsOperators,
    },
    {
      field: "custom2",
      headerName: "Custom2",
      maxWidth: 250,
      align: "center",
      headerAlign: "center",
      sortable: false,
      filterOperators: stringOnlyContainsOperators,
    },
    {
      field: "custom3",
      headerName: "Custom3",
      maxWidth: 80,
      align: "center",
      headerAlign: "center",
      sortable: false,
      filterOperators: stringOnlyContainsOperators,
    },
    {
      field: "custom4",
      headerName: "Custom4",
      minWidth: 40,
      align: "center",
      headerAlign: "center",
      sortable: false,
      filterOperators: stringOnlyContainsOperators,
    },
    {
      field: "custom5",
      headerName: "Custom5",
      minWidth: 60,
      align: "center",
      headerAlign: "center",
      sortable: false,
      filterOperators: stringOnlyContainsOperators,
    },
    {
      field: "formattedSignupDate",
      headerName: "Signup Date",
      minWidth: 200,
      align: "center",
      headerAlign: "center",
      sortable: false,
      filterOperators: stringOnlyContainsOperators,
    },
    {
      field: "email",
      headerName: "Email",
      minWidth: 300,
      align: "center",
      headerAlign: "center",
      sortable: false,
      filterOperators: stringOnlyContainsOperators,
    },
    {
      field: "customerID",
      headerName: "Customer ID",
      maxWidth: 350,
      align: "center",
      headerAlign: "center",
      sortable: false,
      filterOperators: stringOnlyContainsOperators,
    },
    {
      field: "countryCode",
      headerName: "Country Code",
      maxWidth: 40,
      align: "center",
      headerAlign: "center",
      sortable: false,
      filterOperators: stringOnlyContainsOperators,
    },
    {
      field: "saleStatus",
      headerName: "Sale Status",
      minWidth: 150,
      align: "center",
      headerAlign: "center",
      sortable: false,
      filterOperators: stringOnlyContainsOperators,
    },
    {
      field: "campaignHash",
      headerName: "Offer Hash",
      maxWidth: 250,
      align: "center",
      headerAlign: "center",
      sortable: false,
      filterOperators: stringOnlyContainsOperators,
    },
    {
      field: "hasFTD",
      headerName: "Has Conversion",
      mixWidth: 40,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (params.value === 1 ? "Yes" : "No"),
      sortable: false,
      filterOperators: stringOnlyContainsOperators,
    },
    {
      field: "brokerAutologinClick",
      headerName: "Has Autologin",
      mixWidth: 40,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (params.value === null ? "No" : "Yes"),
      sortable: false,
      filterOperators: stringOnlyContainsOperators,
    },
    {
      field: "details",
      headerName: "Details",
      minWidth: 300,
      align: "center",
      headerAlign: "center",
      sortable: false,
      filterOperators: stringOnlyContainsOperators,
      renderCell: (params) => (
        <div
          style={{
            maxHeight: "100%",
            overflowX: "hidden",
            overflowY: "auto",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <p
            style={{
              margin: "12px 0 12px 0",
              lineHeight: "100%",
              fontWeight: 400,
            }}
          >
            Offer Name: <b>{params.row.leadRequestOfferName}</b>
          </p>
          <p
            style={{
              margin: "0 0 12px 0",
              lineHeight: "100%",
              fontWeight: 400,
            }}
          >
            Offer Website: <b>{params.row.leadRequestOfferWebsite}</b>
          </p>
          <p
            style={{
              margin: "0 0 12px 0",
              maxWidth: 290,
              textWrap: "wrap",
              lineHeight: "100%",
              fontWeight: 400,
            }}
          >
            Offer Comment: <b>{params.row.leadRequestComment}</b>
          </p>
        </div>
      ),
    },
  ];

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box>
        <Typography variant="h4" align="center" gutterBottom>
          Lead's board
        </Typography>

        <Typography variant="h5" gutterBottom sx={{ margin: "0 0 12px 0" }}>
          Filter by date
        </Typography>

        <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
          <DatePicker
            label="From Date"
            value={fromDate}
            onChange={(date) => setFromDate(date)}
            format="DD/MM/YYYY"
          />
          <Divider
            orientation="vertical"
            variant="middle"
            flexItem
            sx={{ margin: "0 12px" }}
          />
          <DatePicker
            label="To Date"
            value={toDate}
            onChange={(date) => setToDate(date)}
            format="DD/MM/YYYY"
          />
          <IconButton
            onClick={fetchAllData}
            color="primary"
            sx={{ marginLeft: "12px" }}
          >
            <RefreshIcon />
          </IconButton>
        </Box>

        <Box sx={{ display: "flex", gap: 6, mb: 2, mt: 2 }}>
          <Button onClick={setToday} variant="outlined" size="small">
            Today
          </Button>
          <Button onClick={setYesterday} variant="outlined" size="small">
            Yesterday
          </Button>
          <Button onClick={setLastWeek} variant="outlined" size="small">
            Last Week
          </Button>
          <Button onClick={setLastMonth} variant="outlined" size="small">
            Last Month
          </Button>
          <Button onClick={setThisWeek} variant="outlined" size="small">
            This Week
          </Button>
          <Button onClick={setThisMonth} variant="outlined" size="small">
            This Month
          </Button>
        </Box>

        <Box sx={{ mt: 2, mb: 2 }}>
          <Button
            variant="outlined"
            onClick={handleToggleTestTable}
            endIcon={showTestTable ? <VisibilityOffIcon /> : <VisibilityIcon />}
            size="large"
          >
            {showTestTable ? "Hide Tests Table" : "Show Tests Table"}
          </Button>
        </Box>

        <Typography variant="h5" gutterBottom sx={{ margin: "0 0 12px 0" }}>
          Filter by params
        </Typography>

        <Box
          sx={{
            display: "flex",
            flexDirection: isMobile ? "column" : "row",
            justifyContent: "flex-start",
            mb: 2,
          }}
        >
          <Select
            value={selectedBuyer}
            onChange={(e) => setSelectedBuyer(e.target.value)}
            displayEmpty
            sx={{
              minWidth: 150,
              margin: isMobile ? "0 0 12px 0" : "0 12px 0 0",
            }}
          >
            <MenuItem value="">All Buyers</MenuItem>
            {buyers.map((buyer) => (
              <MenuItem key={buyer} value={buyer}>
                {buyer}
              </MenuItem>
            ))}
          </Select>

          <Select
            value={selectedSaleStatus}
            onChange={(e) => setSelectedSaleStatus(e.target.value)}
            displayEmpty
            sx={{
              minWidth: 150,
              margin: isMobile ? "0 0 12px 0" : "0 12px 0 0",
            }}
          >
            <MenuItem value="">All Statuses</MenuItem>
            {saleStatuses.map((status) => (
              <MenuItem key={status} value={status}>
                {status}
              </MenuItem>
            ))}
          </Select>

          <Select
            value={selectedHasFTD}
            onChange={(e) => setSelectedHasFTD(e.target.value)}
            displayEmpty
            sx={{
              minWidth: 150,
              margin: isMobile ? "0 0 12px 0" : "0 12px 0 0",
            }}
          >
            <MenuItem value="">All Conversions</MenuItem>
            <MenuItem value="Yes">Yes</MenuItem>
            <MenuItem value="No">No</MenuItem>
          </Select>

          <TextField
            label="Email"
            value={emailFilter}
            onChange={(e) => setEmailFilter(e.target.value)}
            sx={{ minWidth: 300 }}
          />
        </Box>

        {showTestTable && (
          <>
            {loading ? (
              <Loader />
            ) : (
              <DataGrid
                rows={testData}
                columns={columns}
                pageSize={10}
                sx={{ ...dataGridStyles, mb: 2 }}
                getRowId={(row) => row.customerID}
              />
            )}
          </>
        )}

        {loading ? (
          <Loader />
        ) : (
          <DataGrid
            key={fromDate + toDate}
            rows={rows}
            columns={columns}
            pageSize={10}
            columnVisibilityModel={columnVisibilityModel}
            onColumnVisibilityModelChange={handleColumnVisibilityChange}
            initialState={{
              pagination: {
                paginationModel: { pageSize: 25, page: 0 },
              },
            }}
            pageSizeOptions={[25, 50, 100, 250, { value: -1, label: "All" }]}
            sx={{ ...dataGridStyles, width: isMobile ? "100%" : "100%" }}
            getRowId={(row) => row.customerID}
            getRowHeight={() => 60}
            getRowClassName={(params) =>
              params.row.hasFTD === 1 ? "row-green" : ""
            }
          />
        )}
      </Box>
    </LocalizationProvider>
  );
};

export default Leaderboard;
