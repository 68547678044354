import React from 'react';
import { Navigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import { getToken } from '../services/authService';

export default function ProtectedRoute({ children, allowedRoles }) {
  const token = getToken();

  if (!token) {
    return <Navigate to="/login" />;
  }

  try {
    const decodedToken = jwtDecode(token);

    if (allowedRoles && !allowedRoles.includes(decodedToken.role)) {
      return <Navigate to="/login" />;
    }

    return children;
  } catch (error) {
    console.error('Invalid token:', error.message);
    return <Navigate to="/login" />;
  }
}
