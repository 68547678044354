import React, { useState, useEffect } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import axios from 'axios';

const theme = createTheme();

function AdminSetting() {
  // Registration form state
  const [registerRole, setRegisterRole] = useState('user');
  const [registerUsername, setRegisterUsername] = useState('');
  const [registerEmail, setRegisterEmail] = useState('');
  const [registerPassword, setRegisterPassword] = useState('');
  const [registerTeam, setRegisterTeam] = useState([]);

  // Edit form state
  const [editRole, setEditRole] = useState('user');
  const [editUsername, setEditUsername] = useState('');
  const [editEmail, setEditEmail] = useState('');
  const [editTeam, setEditTeam] = useState([]);
  const [selectedUserId, setSelectedUserId] = useState('');
  
  // Filter management state
  const [filters, setFilters] = useState({
    targetAffiliateIDs: '',
    excludedBrokerNames: '',
    excludedEmailPrefix: ''
  });

  const [allUsers, setAllUsers] = useState([]);
  const [loading, setLoading] = useState(false);

  // Fetch all users and filters
  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get('https://backend.moorpan.com/get-users');
        setAllUsers(response.data);
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };

    const fetchFilters = async () => {
      try {
        const response = await axios.get('https://backend.moorpan.com/get-filters');
        const filtersData = response.data.reduce((acc, { filter_name, filter_value }) => {
          acc[filter_name] = filter_value;
          return acc;
        }, {});
        setFilters(filtersData);
      } catch (error) {
        console.error('Error fetching filters:', error);
      }
    };

    fetchUsers();
    fetchFilters();
  }, []);

  const handleUserSelect = (event) => {
    const userId = event.target.value;
    setSelectedUserId(userId);
    const selectedUser = allUsers.find((user) => user.id === userId);
    if (selectedUser) {
      setEditUsername(selectedUser.username);
      setEditEmail(selectedUser.email);
      setEditRole(selectedUser.role);
      setEditTeam(selectedUser.team || []);
    }
  };

  const handleRegister = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      await axios.post('https://backend.moorpan.com/register', {
        email: registerEmail,
        password: registerPassword,
        role: registerRole,
        username: registerUsername,
        team: registerRole === 'tlead' ? registerTeam : null,
      });
      alert('User registered successfully');
      setRegisterUsername('');
      setRegisterEmail('');
      setRegisterPassword('');
      setRegisterRole('user');
      setRegisterTeam([]);
    } catch (error) {
      alert('Registration failed: ' + error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleUpdate = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      await axios.put('https://backend.moorpan.com/update-account', {
        id: selectedUserId,
        email: editEmail,
        role: editRole,
        username: editUsername,
        team: editTeam,
      });
      alert('User updated successfully');
    } catch (error) {
      alert('Update failed: ' + error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleFilterChange = (event) => {
    setFilters({ ...filters, [event.target.name]: event.target.value });
  };

  const handleSaveFilters = async () => {
    try {
      await axios.post('https://backend.moorpan.com/update-filters', filters);
      alert('Filters updated successfully');
    } catch (error) {
      alert('Failed to update filters: ' + error.message);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" sx={{ width: '100%' }}>
        <CssBaseline />
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5" align="center">
            Admin Settings
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
            
            {/* Register User Form */}
            <Box component="form" onSubmit={handleRegister} sx={{ mt: 3, width: '45%' }}>
              <Typography component="h2" variant="h6" align="center" sx={{ mb: 2 }}>
                Register User
              </Typography>
              <Stack spacing={2}>
                <TextField
                  required
                  fullWidth
                  label="Username"
                  value={registerUsername}
                  onChange={(e) => setRegisterUsername(e.target.value)}
                />
                <TextField
                  required
                  fullWidth
                  label="Email Address"
                  value={registerEmail}
                  onChange={(e) => setRegisterEmail(e.target.value)}
                />
                <TextField
                  required
                  fullWidth
                  label="Password"
                  type="password"
                  value={registerPassword}
                  onChange={(e) => setRegisterPassword(e.target.value)}
                />
                <FormControl fullWidth>
                  <InputLabel>Role</InputLabel>
                  <Select
                    value={registerRole}
                    onChange={(e) => setRegisterRole(e.target.value)}
                  >
                    <MenuItem value="user">User</MenuItem>
                    <MenuItem value="tlead">Team Lead</MenuItem>
                    <MenuItem value="admin">Admin</MenuItem>
                    <MenuItem value="finance">Finance</MenuItem>
                    <MenuItem value="accm">Account Manager</MenuItem>
                  </Select>
                </FormControl>
                {registerRole === 'tlead' && (
                  <FormControl fullWidth>
                    <InputLabel>Select Team Members</InputLabel>
                    <Select
                      multiple
                      value={registerTeam}
                      onChange={(e) => setRegisterTeam(e.target.value)}
                      renderValue={(selected) =>
                        selected.map((id) => allUsers.find((user) => user.id === id)?.username || id).join(', ')
                      }
                    >
                      {allUsers.map((user) => (
                        <MenuItem key={user.id} value={user.username}>
                          {user.username}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              </Stack>
              <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2, minHeight: 50 }} disabled={loading}>
                {loading ? 'Loading...' : 'Register'}
              </Button>
            </Box>
            
            {/* Edit User Form */}
            <Box component="form" onSubmit={handleUpdate} sx={{ mt: 3, width: '45%' }}>
              <Typography component="h2" variant="h6" align="center" sx={{ mb: 2 }}>
                Edit Account
              </Typography>
              <Stack spacing={2}>
                <FormControl fullWidth>
                  <InputLabel>Select User</InputLabel>
                  <Select
                    value={selectedUserId}
                    onChange={handleUserSelect}
                  >
                    {allUsers.map((user) => (
                      <MenuItem key={user.id} value={user.id}>
                        {user.username}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <TextField
                  fullWidth
                  label="Username"
                  value={editUsername}
                  onChange={(e) => setEditUsername(e.target.value)}
                />
                <TextField
                  fullWidth
                  label="Email"
                  value={editEmail}
                  onChange={(e) => setEditEmail(e.target.value)}
                />
                <FormControl fullWidth>
                  <InputLabel>Role</InputLabel>
                  <Select
                    value={editRole}
                    onChange={(e) => setEditRole(e.target.value)}
                  >
                    <MenuItem value="user">User</MenuItem>
                    <MenuItem value="tlead">Team Lead</MenuItem>
                    <MenuItem value="admin">Admin</MenuItem>
                    <MenuItem value="finance">Finance</MenuItem>
                    <MenuItem value="accm">Account Manager</MenuItem>
                  </Select>
                </FormControl>
                {editRole === 'tlead' && (
                  <FormControl fullWidth>
                    <InputLabel>Select Team Members</InputLabel>
                    <Select
                      multiple
                      value={editTeam}
                      onChange={(e) => setEditTeam(e.target.value)}
                      renderValue={(selected) =>
                        selected.map((id) => allUsers.find((user) => user.id === id)?.username || id).join(', ')
                      }
                    >
                      {allUsers.map((user) => (
                        <MenuItem key={user.id} value={user.username}>
                          {user.username}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              </Stack>
              <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2, minHeight: 50 }} disabled={loading}>
                {loading ? 'Loading...' : 'Update Account'}
              </Button>
            </Box>
          </Box>

          <hr style={{ width: '100%', margin: '40px 0' }} />

          {/* Filter Management Form */}
          <Box sx={{ mt: 4, width: '80%' }}>
            <Typography component="h2" variant="h6" sx={{ mb: 2 }}>Edit Filters</Typography>
            <Stack spacing={2}>
              <TextField
                label="Target Affiliate IDs"
                name="targetAffiliateIDs"
                value={filters.targetAffiliateIDs}
                onChange={handleFilterChange}
                helperText="Comma-separated list of affiliate IDs"
                fullWidth
              />
              <TextField
                label="Excluded Broker Names"
                name="excludedBrokerNames"
                value={filters.excludedBrokerNames}
                onChange={handleFilterChange}
                helperText="Comma-separated list of broker names to exclude"
                fullWidth
              />
              <TextField
                label="Excluded Email Prefix"
                name="excludedEmailPrefix"
                value={filters.excludedEmailPrefix}
                onChange={handleFilterChange}
                fullWidth
              />
            </Stack>
            <Button onClick={handleSaveFilters} fullWidth variant="contained" sx={{ mt: 3 }}>
              Save Filters
            </Button>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
}

export default AdminSetting;
