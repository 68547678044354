import React, { useMemo, useState, useEffect } from "react";
import { DataGrid, getGridStringOperators } from "@mui/x-data-grid";
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Button,
  Typography,
  useMediaQuery,
  Box,
  Divider,
} from "@mui/material";
import { Doughnut } from "react-chartjs-2";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import axios from "axios";
import dayjs from "dayjs";
import Loader from "../components/Loader";
import { processMediaBuyerData } from "../utils";
import { dataGridStyles } from "../styles";
import { IconButton } from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";

ChartJS.register(ArcElement, Tooltip, Legend);

const MediaBuyerAnalytics = () => {
  const [leads, setLeads] = useState([]);
  const [conversions, setConversions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [fromDate, setFromDate] = useState(dayjs().startOf("day"));
  const [toDate, setToDate] = useState(dayjs().endOf("day"));
  const [selectedBuyers, setSelectedBuyers] = useState([]);
  const [allBuyers, setAllBuyers] = useState([]);
  const isMobile = useMediaQuery("(max-width:600px)");

  const stringOnlyContainsOperators = getGridStringOperators().filter(
    (operator) => operator.value === "contains"
  );

  const role = localStorage.getItem("role");
  const username = localStorage.getItem("username");

  const fetchData = async () => {
    setLoading(true);
    try {
      const params = {
        fromDate: fromDate.format("YYYY-MM-DD HH:mm:ss"),
        toDate: toDate.format("YYYY-MM-DD HH:mm:ss"),
        itemsPerPage: 1000,
      };

      if (role === "user" && username) {
        params.custom4 = username;
      }

      const response = await axios.get(
        "https://backend.moorpan.com/get-leads-and-conversions",
        {
          params,
        }
      );

      let leads = response.data.leads;
      let conversions = response.data.conversions;

      if (role === "tlead") {
        const team = JSON.parse(localStorage.getItem("team"));
        leads = leads.filter((entry) => team.includes(entry.custom4));
        conversions = conversions.filter((entry) =>
          team.includes(entry.custom4)
        );
      }

      setLeads(leads);
      setConversions(conversions);

      const uniqueBuyers = [
        ...new Set(leads.map((entry) => entry.custom4 || "empty")),
      ];
      setAllBuyers(uniqueBuyers);
      setSelectedBuyers(uniqueBuyers);
    } catch (error) {
      console.error("Ошибка при загрузке данных:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fromDate, toDate]);

  const rows = useMemo(() => {
    const processedData = processMediaBuyerData(
      leads,
      conversions,
      selectedBuyers
    );

    const totalLeads = processedData.reduce((acc, row) => acc + row.leads, 0);
    const totalDeposits = processedData.reduce(
      (acc, row) => acc + row.deposit,
      0
    );
    const totalCr =
      totalLeads > 0 ? ((totalDeposits / totalLeads) * 100).toFixed(2) : "0";

    const totalRow = {
      id: "total",
      name: "Total",
      leads: totalLeads,
      deposit: totalDeposits,
      cr: totalCr,
    };

    return [...processedData, totalRow];
  }, [leads, conversions, selectedBuyers]);

  const processGeoData = (leads, conversions, selectedBuyers) => {
    const leadsMap = {};
    const geoMap = {};

    // Фильтруем лиды по выбранным байерам и добавляем их в leadsMap
    leads.forEach((lead) => {
      const mediaBuyerName = lead.custom4 || "empty";
      if (selectedBuyers.includes(mediaBuyerName)) {
        const traderID = lead.traderID.toString();
        leadsMap[traderID] = lead;
      }
    });

    // Добавляем данные по лидам в geoMap
    Object.values(leadsMap).forEach((lead) => {
      const countryCode = lead.countryCode || "unknown";
      if (!geoMap[countryCode]) {
        geoMap[countryCode] = {
          countryCode,
          leads: 0,
          deposit: 0,
          cr: "0.00",
        };
      }
      geoMap[countryCode].leads += 1;
    });

    // Фильтруем депозиты по selectedBuyers и добавляем их в geoMap
    conversions.forEach((conversion) => {
      const traderID = conversion.traderID.toString();
      const countryCode = conversion.countryCode || "unknown";
      const mediaBuyerName = conversion.custom4 || "empty";

      if (selectedBuyers.includes(mediaBuyerName)) {
        if (leadsMap[traderID]) {
          geoMap[countryCode].deposit += 1;
        } else {
          if (!geoMap[countryCode]) {
            geoMap[countryCode] = {
              countryCode,
              leads: 0,
              deposit: 0,
              cr: "0.00",
            };
          }
          geoMap[countryCode].deposit += 1;
        }
      }
    });

    // Рассчитываем CR
    Object.keys(geoMap).forEach((country) => {
      const leads = geoMap[country].leads;
      const deposits = geoMap[country].deposit;
      geoMap[country].cr =
        leads > 0 ? ((deposits / leads) * 100).toFixed(2) : "0.00";
    });

    return geoMap;
  };

  const geoDataProcessed = useMemo(() => {
    const processedGeoData = processGeoData(leads, conversions, selectedBuyers);
    return processedGeoData;
  }, [leads, conversions, selectedBuyers]);

  const geoDataRows = useMemo(() => {
    const rows = Object.keys(geoDataProcessed).map((key, index) => ({
      id: `geo-${index}`,
      countryCode: key,
      leads: geoDataProcessed[key].leads,
      deposit: geoDataProcessed[key].deposit,
      cr: geoDataProcessed[key].cr,
    }));

    const totalLeads = rows.reduce((acc, row) => acc + row.leads, 0);
    const totalDeposits = rows.reduce((acc, row) => acc + row.deposit, 0);
    const totalCr =
      totalLeads > 0 ? ((totalDeposits / totalLeads) * 100).toFixed(2) : "0";

    const totalRow = {
      id: "geo-total",
      countryCode: "Total",
      leads: totalLeads,
      deposit: totalDeposits,
      cr: totalCr,
    };

    return [...rows, totalRow];
  }, [geoDataProcessed]);

  const chartData = {
    labels: Object.keys(geoDataProcessed),
    datasets: [
      {
        label: "Leads",
        data: Object.values(geoDataProcessed).map((item) => item.leads),
        backgroundColor: [
          "#D91E18",
          "#E67E23",
          "#F9BF3B",
          "#2FCC71",
          "#1BB5FE",
          "#1F3A93",
          "#F62496",
          "#9A13B3",
          "#95411B",
          "#AEB8B8",
        ],
        hoverOffset: 4,
      },
    ],
  };

  const handleBuyerChange = (buyer) => {
    setSelectedBuyers((prev) =>
      prev.includes(buyer) ? prev.filter((b) => b !== buyer) : [...prev, buyer]
    );
  };

  const handleUnselectAll = () => {
    setSelectedBuyers([]);
  };

  const handleSelectAll = () => {
    setSelectedBuyers(allBuyers);
  };

  const columns = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      sortComparator: (v1, v2, params1, params2) => {
        if (params1.id === "total" || params2.id === "total") return 0;
        return v1.localeCompare(v2);
      },
      filterOperators: stringOnlyContainsOperators,
    },
    {
      field: "leads",
      headerName: "Leads",
      flex: 1,
      sortComparator: (v1, v2, params1, params2) => {
        if (params1.id === "total" || params2.id === "total") return 0;
        return v1 - v2;
      },
      filterOperators: stringOnlyContainsOperators,
    },
    {
      field: "deposit",
      headerName: "Deposits",
      flex: 1,
      sortComparator: (v1, v2, params1, params2) => {
        if (params1.id === "total" || params2.id === "total") return 0;
        return v1 - v2;
      },
      filterOperators: stringOnlyContainsOperators,
    },
    {
      field: "cr",
      headerName: "CR (%)",
      flex: 1,
      renderCell: (params) => `${params.value}%`,
      sortComparator: (v1, v2, params1, params2) => {
        if (params1.id === "total" || params2.id === "total") return 0;
        return parseFloat(v1) - parseFloat(v2);
      },
      filterOperators: stringOnlyContainsOperators,
    },
  ];

  const geoColumns = [
    {
      field: "countryCode",
      headerName: "Country Code",
      flex: 1,
      sortComparator: (v1, v2, params1, params2) => {
        if (params1.id === "geo-total" || params2.id === "geo-total") return 0;
        return v1.localeCompare(v2);
      },
      filterOperators: stringOnlyContainsOperators,
    },
    {
      field: "leads",
      headerName: "Leads",
      flex: 1,
      sortComparator: (v1, v2, params1, params2) => {
        if (params1.id === "geo-total" || params2.id === "geo-total") return 0;
        return v1 - v2;
      },
      filterOperators: stringOnlyContainsOperators,
    },
    {
      field: "deposit",
      headerName: "Deposits",
      flex: 1,
      sortComparator: (v1, v2, params1, params2) => {
        if (params1.id === "geo-total" || params2.id === "geo-total") return 0;
        return v1 - v2;
      },
      filterOperators: stringOnlyContainsOperators,
    },
    {
      field: "cr",
      headerName: "CR (%)",
      flex: 1,
      renderCell: (params) => `${params.value}%`,
      sortComparator: (v1, v2, params1, params2) => {
        if (params1.id === "geo-total" || params2.id === "geo-total") return 0;
        return parseFloat(v1) - parseFloat(v2);
      },
      filterOperators: stringOnlyContainsOperators,
    },
  ];

  const setToday = () => {
    setFromDate(dayjs().startOf("day"));
    setToDate(dayjs().endOf("day"));
  };

  const setYesterday = () => {
    setFromDate(dayjs().subtract(1, "day").startOf("day"));
    setToDate(dayjs().subtract(1, "day").endOf("day"));
  };

  const setLastWeek = () => {
    setFromDate(dayjs().subtract(1, "week").startOf("week"));
    setToDate(dayjs().subtract(1, "week").endOf("week"));
  };

  const setLastMonth = () => {
    setFromDate(dayjs().subtract(1, "month").startOf("month"));
    setToDate(dayjs().subtract(1, "month").endOf("month"));
  };

  const setThisWeek = () => {
    setFromDate(dayjs().startOf("week"));
    setToDate(dayjs().endOf("week"));
  };

  const setThisMonth = () => {
    setFromDate(dayjs().startOf("month"));
    setToDate(dayjs().endOf("month"));
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box>
        <Typography variant="h4" align="center" gutterBottom>
          Media Buyer Analytics
        </Typography>

        <Typography variant="h5" gutterBottom sx={{ margin: "0 0 12px 0" }}>
          Filter by date
        </Typography>

        <Box sx={{ display: "flex", justifyContent: "flex-start", mb: 2 }}>
          <DatePicker
            label="From Date"
            value={fromDate}
            onChange={(date) => setFromDate(date)}
            format="DD/MM/YYYY"
          />
          <Divider
            orientation="vertical"
            variant="middle"
            flexItem
            sx={{ margin: "0 12px" }}
          />
          <DatePicker
            label="To Date"
            value={toDate}
            onChange={(date) => setToDate(date)}
            format="DD/MM/YYYY"
          />
          <IconButton
            onClick={fetchData}
            color="primary"
            sx={{ marginLeft: "12px" }}
          >
            <RefreshIcon />
          </IconButton>
        </Box>

        <Box sx={{ display: "flex", gap: 3, mb: 2, mt: 2 }}>
          <Button onClick={setToday} variant="outlined" size="small">
            Today
          </Button>
          <Button onClick={setYesterday} variant="outlined" size="small">
            Yesterday
          </Button>
          <Button onClick={setLastWeek} variant="outlined" size="small">
            Last Week
          </Button>
          <Button onClick={setLastMonth} variant="outlined" size="small">
            Last Month
          </Button>
          <Button onClick={setThisWeek} variant="outlined" size="small">
            This Week
          </Button>
          <Button onClick={setThisMonth} variant="outlined" size="small">
            This Month
          </Button>
        </Box>

        <Typography variant="h5" gutterBottom>
          Filter by Media Buyer
        </Typography>

        <FormGroup row sx={{ margin: "0 0 12px 0" }}>
          <Button
            onClick={handleUnselectAll}
            variant="outlined"
            color="primary"
            sx={{ marginRight: "16px" }}
          >
            Unselect All
          </Button>
          <Button
            onClick={handleSelectAll}
            variant="outlined"
            color="primary"
            sx={{ marginRight: "16px" }}
          >
            Select All
          </Button>
          {allBuyers.map((buyer) => (
            <FormControlLabel
              key={buyer}
              control={
                <Checkbox
                  checked={selectedBuyers.includes(buyer)}
                  onChange={() => handleBuyerChange(buyer)}
                  color="primary"
                />
              }
              label={buyer}
            />
          ))}
        </FormGroup>

        {loading ? (
          <Loader />
        ) : (
          <DataGrid
            rows={rows}
            columns={columns}
            pageSize={5}
            rowsPerPageOptions={[5, 10, 25]}
            getRowClassName={(params) =>
              params.indexRelativeToCurrentPage % 2 === 0
                ? "MuiDataGrid-even-row"
                : ""
            }
            sx={dataGridStyles}
          />
        )}

        <Box
          sx={{
            display: "flex",
            flexDirection: isMobile ? "column" : "row",
            justifyContent: "space-between",
            width: "100%",
            mt: 4,
          }}
        >
          <Box sx={{ width: isMobile ? "100%" : "50%", mb: 2 }}>
            <Typography variant="h5" align="center" gutterBottom>
              Geo Analytics
            </Typography>
            <DataGrid
              rows={geoDataRows}
              columns={geoColumns}
              pageSize={5}
              rowsPerPageOptions={[5]}
              getRowClassName={(params) =>
                params.indexRelativeToCurrentPage % 2 === 0
                  ? "MuiDataGrid-even-row"
                  : ""
              }
              sx={dataGridStyles}
            />
          </Box>

          <Box sx={{ width: isMobile ? "100%" : "45%", mt: isMobile ? 2 : 0 }}>
            <Typography variant="h5" align="center" gutterBottom>
              Leads by Geo
            </Typography>
            <Doughnut data={chartData} />
          </Box>
        </Box>
      </Box>
    </LocalizationProvider>
  );
};

export default MediaBuyerAnalytics;
